export default {
    MAX_ATTACHMENT_SIZE: 5000000,

    s3: {
      REGION: "us-east-1",
      BUCKET: "secure-transfer-files1"
    },
    apiGateway: {
      REGION: "us-east-2",
      URL: "https://lu3fcvwpfl.execute-api.us-east-2.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_Ujtn4VYvY",
      APP_CLIENT_ID: "3envf130h07oikkqlic40bhq9m",
      IDENTITY_POOL_ID: "us-east-2:7813de2d-fc60-436c-8a9f-a6ec2b2b06bc"
    }
  };